<template>
  <div class="register">
    <el-page-header @back="goBack" content="注册账号"> </el-page-header>

    <div class="mt20">
      <div class="mb15 iptBox">
        <el-input placeholder="请输入您的账号" prefix-icon="el-icon-user" v-model="username"></el-input>
      </div>
      <div class="mb15 iptBox">
        <el-input placeholder="请输入您的密码" prefix-icon="el-icon-lock" show-password v-model="password"></el-input>
      </div>
      <div class="mb15 iptBox">
        <el-input placeholder="请确认您的密码" prefix-icon="el-icon-lock" show-password v-model="passwords"></el-input>
      </div>
      <div class="mb15 iptBox">
        <el-input placeholder="请输入您的手机号" prefix-icon="el-icon-phone-outline" v-model="phone"></el-input>
      </div>
      <div class="mb15 iptBox">
        <el-input placeholder="请输入您的邮箱" prefix-icon="el-icon-message" v-model="email"></el-input>
      </div>
      <div class="mb15 iptBox">
        <el-input placeholder="请输入您的验证码" prefix-icon="el-icon-copy-document" @keyup.enter.native="register"
          v-model="code">
          <template slot="append">
            <el-button v-show="!codeShow" type="primary" @click="send">获取邮箱验证码</el-button>
            <el-button v-show="codeShow" type="primary" disabled>{{ count }}秒后重试</el-button>
          </template>
        </el-input>
      </div>
    </div>

    <el-button type="primary" class="login" @click="register">注册</el-button>
  </div>
</template>
<script>
import { send_code, register } from "@/api/user.js";

export default {
  name: "register",
  props: {
    text: {
      type: String,
      default: "内容",
    },
  },
  data() {
    return {
      username: "",
      password: "",
      passwords: "",
      email: "",
      phone: "",
      count: "",
      code: "",
      codeShow: false,
      timer: null,
    };
  },
  mounted() { },
  methods: {
    async send() {
      if (!this.email) {
        this.$message.error("请输入邮箱");
        return;
      }
      let clicktime = new Date().getTime() + 60000; //未来60秒，这里也毫秒为单位
      sessionStorage.setItem("EndTime", JSON.stringify(clicktime)); //存入sessionStorage
      // this.$message.success("发送成功");
      // this.timeDown(clicktime); //请求成功则触发timeDown并将时间携带过去

      let res = await send_code({
        email: this.email,
        event: 1,
      });
      if (res.code == 200) {
        this.$message.success("发送成功");
        this.timeDown(clicktime); //请求成功则触发timeDown并将时间携带过去
      } else {
        this.$message.error(res.msg);
      }
    },
    timeDown(time) {
      if (!this.timer) {
        this.count = Math.ceil((JSON.parse(time) - new Date().getTime()) / 1000); //取出计时
        console.log(111, this.count);

        this.codeShow = true;
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            this.codeShow = false;
            clearInterval(this.timer); //清除计时器
            this.timer = null;
            sessionStorage.removeItem("EndTime"); //计时完后清除sessionStorage
          }
        }, 1000);
      }
    },
    async register() {
      let params = {
        username: this.username,
        password: this.password,
        passwords: this.passwords,
        email: this.email,
        phone: this.phone,
        code: this.code,
      };
      let res = await register(params);
      if (res.code == "200") {
        this.$message.success(res.msg);
        setTimeout(() => {
          this.goBack();
        }, 700);
      } else {
        this.$message.error(res.msg);
      }
    },
    goBack() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less" scoped>
.register {
  font-size: 15px;
  margin-top: 15px;
  margin: 30px 40px 0;

  .login {
    width: 90%;
    background: #3370ff;
    margin: 18px 5% 15px;
    height: 45px;
    font-size: 16px;
  }

  .title {
    font-family: MiSans, MiSans;
    font-weight: 600;
    font-size: 36px;
    color: #000000;
    line-height: 48px;
  }

  .tips {
    margin: 5px 0 15px;
    font-family: MiSans, MiSans;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    line-height: 27px;
  }

  .chek {
    margin: 0 auto;
  }
}

::v-deep {
  .el-input__inner {
    background: #f7f7f7 !important;
    border-radius: 6px !important;
    border: 0 !important;
  }

  // .el-input__icon {
  //   width: 35px;
  //   line-height: 45px;
  //   font-size: 16px;
  //   font-weight: bold;
  // }

  .el-checkbox__label {
    font-size: 13px;
  }

  .el-tabs__item.is-active,
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #3370ff;
  }

  .el-tabs__active-bar {
    background: #3370ff;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #3370ff;
    border-color: #3370ff;
  }

  .el-button--primary {
    color: #fff !important;
  }

  .el-tabs__item {
    font-size: 16px;
  }

  .el-input-group__append {
    border: 0;
  }

  p {
    max-width: 100%;
    text-align: left;
    text-indent: 24px;
    font-size: 16px;
  }

  .text {
    padding: 30px;
    padding-top: 0px;
  }
}
</style>